gallery {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: block;
  height: 500px;
  background-color: black;
  * {
    box-sizing: border-box;
  }
}

gallery, gallery-core {
  position: relative;
  overflow: hidden;
}

gallery-core, .g-box, .g-slider {
  display: flex;
  height: 100%;
  width: 100%;
}

gallery[fluid] {
  transform: translateX(-50vw);
  width: 100vw;
  left: 50%;
  &[fluid='false'] {
    transform: none;
    width: initial;
    left: initial;
  }
}

.g-no-transition {
  transition: unset !important;
}

.g-box, gallery-slider {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  order: 1;
  height: 100%;
}

gallery-nav, .g-btn-close {
  svg {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 0px 1px black);
    transition: opacity linear 0.2s;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}
