@use 'sass:map';
@use '@angular/material' as mat;
@import '../utilities/colors';

a {
  &.link-primary {
    color: $eform-red;
    &:hover {
      color: $eform-red-dark;
    }
  }
}

.text-danger {
  color: $eform-red;
}

.text-warning {
  color: $eform-orange;
}

.text-black {
  color: rgba(0, 0, 0, 0.87);
}

.text-white {
  color: white;
}
@each $key in (nowrap, unset, break-spaces, normal, pre, pre-line, pre-wrap) {
  .text-#{$key} {
    white-space: $key;
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $warn-palette: map.get($color-config, 'warn');
  $accent-palette: map.get($color-config, 'accent');
  $primary-palette: map.get($color-config, 'primary');

  @each $key, $val in (('warn', $warn-palette), ('accent', $accent-palette), ('primary', $primary-palette)) {
    .text-#{$key} {
      color: mat.m2-get-color-from-palette($val, 500);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
