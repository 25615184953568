@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);

  $warn-palette: map.get($color-config, warn);
  $accent-palette: map.get($color-config, accent);
  $primary-palette: map.get($color-config, primary);
  $background: map.get($theme, 'background');

  @each $key, $val in (('warn', $warn-palette), ('accent', $accent-palette), ('primary', $primary-palette)) {
    .ngx-toastr-#{$key} {
      background-color: mat.m2-get-color-from-palette($val, 500);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
