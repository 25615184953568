/**
 * Checkbox Variables
 */

$checkbox-size            : 25px
$checkbox-font-size       : 18px

$checkbox-border-radius   : 2px

$checkbox-text-color      : #5B5B5B
$checkbox-inactive-color  : lighten(#b2b2b2, 10)
$checkbox-active-color    : #333333 // e-form-dark
$checkbox-inner-color     : #fff
$checkbox-error-color     : #AE0101 // red-700

$checkbox-transition      : all 0.25s ease-in-out
