lightbox {
  position: relative;
  display: block;
  width: 1100px;
  height: 800px;
  max-width: 94vw;
  max-height: 90vh;

  gallery {
    overflow: hidden;
    margin: 0;
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
}

.g-backdrop {
  background-color: rgba(0, 0, 0, 0.9);
}

@mixin fullscreen() {
  width: 100%;
  lightbox {
    max-width: unset;
    max-height: unset;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
}

.fullscreen {
  @include fullscreen();
}

.g-overlay {
  margin: auto;

  @media only screen and (max-width: 480px) {
    @include fullscreen();
  }
}

// OVERLAY STYLE FROM 'https://github.com/angular/material2/blob/master/src/cdk/overlay/_overlay.scss'

$cdk-z-index-overlay-container: 1000;
$cdk-z-index-overlay: 1000;
$cdk-z-index-overlay-backdrop: 1000;

// Background color for all of the backdrops
$cdk-overlay-dark-backdrop-background: rgba(0, 0, 0, 0.6);

// Default backdrop animation is based on the Material Design swift-ease-out.
$backdrop-animation-duration: 400ms !default;
$backdrop-animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  // Disable events from being captured on the overlay container.
  pointer-events: none;

  // The container should be the size of the viewport.
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

// The overlay-container is an invisible element which contains all individual overlays.
.cdk-overlay-container {
  position: fixed;
  z-index: $cdk-z-index-overlay-container;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: $cdk-z-index-overlay;
}

// A single overlay pane.
.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: $cdk-z-index-overlay;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: $cdk-z-index-overlay-backdrop;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity $backdrop-animation-duration
  $backdrop-animation-timing-function;
  opacity: 0;

  &.cdk-overlay-backdrop-showing {
    opacity: 0.48;
  }
}

.cdk-overlay-dark-backdrop {
  background: $cdk-overlay-dark-backdrop-background;
}

.cdk-overlay-transparent-backdrop {
  background: none;
}

// Used when disabling global scrolling.
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.g-btn-close {
  position: absolute;
  right: 0.9em;
  top: 0.9em;
  z-index: 60;
  cursor: pointer;
  width: 20px;
  height: 20px;
  @media only screen and (max-width: 480px) {
    right: 0.7em;
    top: 0.7em;
  }
}

