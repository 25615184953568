/*
@import "../utilities/colors";
$highlight-color: $eform-gray;
body.theme-dark {
  .ng-select-wrapper {
    margin-top: 12px;
  }

  .ng-select {
    padding-bottom: 1.25em;
    width: 100%;
    &.ng-select-disabled {
      .ng-select-container {
        &:after {
          border-bottom-color: transparent;
          background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
          background-size: 4px 1px;
          background-repeat: repeat-x;
        }
        .ng-value-container {
          .ng-value {
            color: rgba(0, 0, 0, 0.5);
          }
          .ng-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
      .ng-arrow-wrapper .ng-arrow,
      .ng-clear-wrapper {
        color: rgba(0, 0, 0, 0.38);
      }
    }
    &.ng-select-focused {
      .ng-select-container {
        &:after {
          border-color: $highlight-color;
          border-width: 2px;
        }
        .ng-value-container .ng-placeholder {
          transform: translateY(-0.99em) scale(.75) perspective(100px) translateZ(.001px);
          //color: $highlight-color;
        }
        .ng-arrow-wrapper .ng-arrow {
          //color: $highlight-color;
        }
      }
    }
    .ng-has-value,
    &.ng-select-filtered .ng-select-container {
      .ng-placeholder {
        display: initial;
        position: absolute;
      }
    }
    .ng-has-value,
    &.ng-select-opened {
      .ng-placeholder {
        transform: translateY(-0.9em) scale(.75) perspective(100px) translateZ(.001px);
      }
    }
    .ng-select-container {
      align-items: baseline;
      max-height: 46px;
      &:after {
        border-bottom: 1px solid rgba(255, 255, 255, 0.7);
        content: '';
        width: 100%;
        bottom: 0;
        position: absolute;
        transition: border-color .3s cubic-bezier(.55, 0, .55, .2);
      }
      .ng-value-container {
        align-items: baseline;
        padding: .4375em 0 3.5px;
        border-top: .84375em solid transparent;
        .ng-placeholder {
          //position: absolute;
          //color: var(--black-350);
          transform-origin: 0 !*0*!;
          transition: transform .4s cubic-bezier(.25, .8, .25, 1), color .4s cubic-bezier(.25, .8, .25, 1), width .4s cubic-bezier(.25, .8, .25, 1);
        }
        .ng-input {
          bottom: .4375em;
        }
      }
    }
    &.ng-select-single {
      .ng-select-container {
        .ng-arrow-wrapper {
          align-self: flex-end;
          bottom: 9px;
        }
        .ng-clear-wrapper {
          align-self: flex-end;
          bottom: 7px;
        }
        .ng-value-container {
          overflow: unset;
        }
      }
    }
    &.ng-select-multiple {
      &.ng-select-disabled {
        .ng-select-container .ng-value-container .ng-value {
          background-color: rgba(0, 0, 0, .12);
          color: rgba(0, 0, 0, .26);
        }
      }
      .ng-select-container {
        .ng-value-container {
          .ng-value {
            padding: .4em .5em;
            margin: 2px 2px 2px 0;
            line-height: 1;
            white-space: nowrap;
            text-decoration: none;
            text-align: center;
            border-width: 1px;
            border-style: solid;
            border-radius: 3px;
            background-color: var(--theme-tag-background-color);
            color: var(--theme-tag-color);
            border-color: var(--theme-tag-background-color);
            &.ng-value-disabled {
              background-color: rgba(0, 0, 0, .12);
              color: rgba(0, 0, 0, .26);
            }
            .ng-value-label {
              font-size: 14px;
              font-weight: 500
            }
            .ng-value-icon {
              color: rgba(255, 255, 255, .54);
              padding-right: 5px;
              &:hover {
                color: rgba(255, 255, 255, .87);
              }
            }
          }
          .ng-input {
            line-height: 1.375em;
          }
        }
        &.ng-has-value {
          align-items: center;
          .ng-value-container {
            padding-bottom: 0;
            padding-top: .1875em;
          }
          .ng-clear-wrapper,
          .ng-arrow-wrapper {
            border-top: .84375em solid transparent;
          }
        }
      }
    }
    .ng-clear-wrapper {
      color: rgba(0, 0, 0, 0.54);
      &:hover {
        color: rgba(0, 0, 0, .87);
      }
    }
    .ng-arrow-wrapper {
      bottom: 2px;
      .ng-arrow {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid;
        margin: 0 4px;
        //color: rgba(0, 0, 0, .54);
      }
    }
    .ng-spinner-zone {
      top: 3px;
    }
  }

  .ng-dropdown-panel {
    z-index: 100000;
    background: var(--black-025);
    &.ng-select-bottom {
      top: calc(100% - 1.25em);
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
    }
    &.ng-select-top {
      bottom: calc(100% - .84375em);
      box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, .2), 0 -8px 10px 1px rgba(0, 0, 0, .14), 0 -3px 14px 2px rgba(0, 0, 0, .12);
    }
    &.multiple {
      .ng-option {
        &.selected {
          background: #fff;
        }
        &.marked {
          background: rgba(0, 0, 0, .04);
        }
      }
    }
    .ng-dropdown-header {
      border-bottom: 1px solid rgba(0, 0, 0, .12);
      padding: 0 16px;
      line-height: 3em;
      min-height: 3em;
    }
    .ng-dropdown-footer {
      border-top: 1px solid rgba(0, 0, 0, .12);
      padding: 0 16px;
      line-height: 3em;
      min-height: 3em;
    }
    .ng-dropdown-panel-items {
      .ng-optgroup {
        user-select: none;
        cursor: pointer;
        line-height: 3em;
        height: 3em;
        padding: 0 16px;
        //color: rgba(0, 0, 0, .54);
        font-weight: 500;
        &.ng-option-marked {
          background: rgba(0, 0, 0, .04);
        }
        &.ng-option-disabled {
          cursor: default;
        }
        &.ng-option-selected {
          background: rgba(0, 0, 0, .12);
          //color: $highlight-color;
        }
      }
      .ng-option {
        line-height: 3em;
        min-height: 3em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 16px;
        text-align: left;
        text-decoration: none;
        position: relative;
        color: var(--theme-body-font-color);
        &.ng-option-marked {
          background: rgba(0, 0, 0, .04);
          //color: var(--black-600);
        }
        &.ng-option-selected {
          background: rgba(0, 0, 0, .25);
          //color: var(--black-900);
        }
        &.ng-option-disabled {
          //color: rgba(0, 0, 0, 0.38);
        }
        &.ng-option-child {
          padding-left: 32px;
        }
        .ng-tag-label {
          padding-right: 5px;
          font-size: 80%;
          font-weight: 400;
          color: var(--black-600);
        }
      }
    }
  }
}

body.theme-light {
  .ng-select-wrapper {
    margin-top: 12px;
  }

  .ng-select {
    padding-bottom: 1.25em;
    width: 100%;

    &.ng-select-disabled {
      .ng-select-container {
        &:after {
          border-bottom-color: transparent;
          background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
          background-size: 4px 1px;
          background-repeat: repeat-x;
        }

        .ng-value-container {
          .ng-value {
            color: rgba(0, 0, 0, 0.5);
          }

          .ng-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }

      .ng-arrow-wrapper .ng-arrow,
      .ng-clear-wrapper {
        color: rgba(0, 0, 0, 0.38);
      }
    }

    &.ng-select-focused {
      .ng-select-container {
        &:after {
          border-color: $highlight-color;
          border-width: 2px;
        }

        .ng-value-container .ng-placeholder {
          transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px);
          color: $highlight-color;
        }

        .ng-arrow-wrapper .ng-arrow {
          color: $highlight-color;
        }
      }
    }

    .ng-has-value,
    &.ng-select-filtered .ng-select-container {
      .ng-placeholder {
        display: initial;
      }
    }

    .ng-has-value,
    &.ng-select-opened {
      .ng-placeholder {
        transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px);
      }
    }

    .ng-select-container {
      align-items: baseline;
      min-height: 51.5px;

      &:after {
        border-bottom: 1px solid rgba(0, 0, 0, .20);
        content: '';
        width: 100%;
        bottom: 0;
        position: absolute;
        transition: border-color .3s cubic-bezier(.55, 0, .55, .2);
      }

      .ng-value-container {
        align-items: baseline;
        padding: .4375em 0;
        border-top: .84375em solid transparent;

        .ng-placeholder {
          position: absolute;
          color: rgba(0, 0, 0, 0.5);
          transform-origin: 0 0;
          transition: transform .4s cubic-bezier(.25, .8, .25, 1), color .4s cubic-bezier(.25, .8, .25, 1), width .4s cubic-bezier(.25, .8, .25, 1);
        }

        .ng-input {
          bottom: .4375em;
        }
      }
    }

    &.ng-select-single {
      .ng-select-container {
        .ng-arrow-wrapper {
          align-self: flex-end;
          bottom: 9px;
        }

        .ng-clear-wrapper {
          align-self: flex-end;
          bottom: 7px;
        }
      }
    }

    &.ng-select-multiple {
      &.ng-select-disabled {
        .ng-select-container .ng-value-container .ng-value {
          background-color: rgba(0, 0, 0, .12);
          color: rgba(0, 0, 0, .26);
        }
      }

      .ng-select-container {
        .ng-value-container {
          .ng-value {
            margin-right: 0.4375em;
            margin-bottom: 0.4375em;
            background-color: $highlight-color;
            border-radius: 2px;
            color: #fff;
            padding: 2px 5px;

            &.ng-value-disabled {
              background-color: rgba(0, 0, 0, .12);
              color: rgba(0, 0, 0, .26);
            }

            .ng-value-label {
              font-size: 14px;
              font-weight: 500
            }

            .ng-value-icon {
              color: rgba(255, 255, 255, .54);
              padding-right: 5px;

              &:hover {
                color: rgba(255, 255, 255, .87);
              }
            }
          }

          .ng-input {
            line-height: 1.375em;
          }
        }

        &.ng-has-value {
          align-items: center;

          .ng-value-container {
            padding-bottom: 0;
            padding-top: .1875em;
          }

          .ng-clear-wrapper,
          .ng-arrow-wrapper {
            border-top: .84375em solid transparent;
          }
        }
      }
    }

    .ng-clear-wrapper {
      color: rgba(0, 0, 0, 0.54);

      &:hover {
        color: rgba(0, 0, 0, .87);
      }
    }

    .ng-arrow-wrapper {
      bottom: 2px;

      .ng-arrow {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid;
        margin: 0 4px;
        color: rgba(0, 0, 0, .54);
      }
    }

    .ng-spinner-zone {
      top: 3px;
    }
  }

  .ng-dropdown-panel {
    z-index: 100000;
    background: #fff;

    &.ng-select-bottom {
      top: calc(100% - 1.25em);
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
    }

    &.ng-select-top {
      bottom: calc(100% - .84375em);
      box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, .2), 0 -8px 10px 1px rgba(0, 0, 0, .14), 0 -3px 14px 2px rgba(0, 0, 0, .12);
    }

    &.multiple {
      .ng-option {
        &.selected {
          background: #fff;
        }

        &.marked {
          background: rgba(0, 0, 0, .04);
        }
      }
    }

    .ng-dropdown-header {
      border-bottom: 1px solid rgba(0, 0, 0, .12);
      padding: 0 16px;
      line-height: 3em;
      min-height: 3em;
    }

    .ng-dropdown-footer {
      border-top: 1px solid rgba(0, 0, 0, .12);
      padding: 0 16px;
      line-height: 3em;
      min-height: 3em;
    }

    .ng-dropdown-panel-items {
      .ng-optgroup {
        user-select: none;
        cursor: pointer;
        line-height: 3em;
        height: 3em;
        padding: 0 16px;
        color: rgba(0, 0, 0, .54);
        font-weight: 500;

        &.ng-option-marked {
          background: rgba(0, 0, 0, .04);
        }

        &.ng-option-disabled {
          cursor: default;
        }

        &.ng-option-selected {
          background: rgba(0, 0, 0, .12);
          //color: $highlight-color;
        }
      }

      .ng-option {
        line-height: 3em;
        min-height: 3em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 16px;
        text-align: left;
        text-decoration: none;
        position: relative;
        //color: rgba(0, 0, 0, .87);

        &.ng-option-marked {
          background: rgba(0, 0, 0, .04);
          //color: rgba(0, 0, 0, .87);
        }

        &.ng-option-selected {
          background: rgba(0, 0, 0, .12);
          //color: $highlight-color;
        }

        &.ng-option-disabled {
          //color: rgba(0, 0, 0, 0.38);
        }

        &.ng-option-child {
          padding-left: 32px;
        }

        .ng-tag-label {
          padding-right: 5px;
          font-size: 80%;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.38);
        }
      }
    }
  }
}
*/
body {
  .ng-dropdown-panel{
    z-index: 100000;
  }
}
