@import "../utilities/colors";

i {
  &.material-icons {
    &.md-18 { font-size: 18px; }
    &.md-24 { font-size: 24px; }
    &.md-36 { font-size: 36px; }
    &.md-48 { font-size: 48px; }
    
    &.material-icon-pointer {
      cursor: pointer;
    }

    &.material-icon-danger {
      color: $eform-red-dark
    }
    &.material-icon-success {
      color: $eform-green-dark
    }
  }
}
