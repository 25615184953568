.md-form {
  &.md-form-case-elem {
    margin-top: 0.3rem;
    margin-bottom: 0;
  }
  &.md-form-no-spacing {
    margin: 0 !important;
    padding: 0 !important;
  }
  &.md-form-sm {
    margin: 1rem !important;
    padding: 0 !important;
  }
}

.mat-mdc-form-field {
  width: 100%;
}
