.card-header-extended {
  padding: 0.3rem 1.7rem;
}

.card-header-simple {
  padding: 1.2rem 1.7rem;
}

.card-body-no-spaces {
  padding: 0.2rem 1rem;
}
