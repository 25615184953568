gallery-thumbs {
  display: block;
  z-index: 1;
  overflow: unset;
}

.g-thumbs-container {
  position: relative;
  z-index: 206;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  overflow: unset;
}

gallery-core[disableThumb='true'] {
  gallery-thumb {
    cursor: default;
  }
}

gallery-core[thumbPosition='top'],
gallery-core[thumbPosition='bottom'] {
  gallery-thumbs .g-slider {
    flex-direction: row;
    top: 0;
    left: 50%;
  }
  gallery-thumb {
    padding: 1px 0 1px 1px;
  }
}

gallery-core[thumbPosition='left'],
gallery-core[thumbPosition='right'] {
  gallery-thumbs {
    .g-slider {
      flex-direction: column;
      top: 50%;
      left: 0;
    }
  }
  gallery-thumb {
    padding: 0 1px 1px 1px;
  }
}

gallery-core[thumbPosition='top'] {
  flex-direction: column;
}

gallery-core[thumbPosition='left'] {
  flex-direction: row;
}

gallery-core[thumbPosition='right'] {
  flex-direction: row-reverse;
}

gallery-core[thumbPosition='bottom'] {
  flex-direction: column-reverse;
}

$loading-color: #fff !default;
$placeholder-ng: #ced4da !default;

.g-thumb-loading {
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: $placeholder-ng;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation .8s linear infinite;
    background: linear-gradient(to right, rgba($loading-color, 0) 46%, rgba($loading-color, .35) 50%, rgba($loading-color, 0) 54%) 50% 50%;
  }
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}
