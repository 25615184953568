@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $is-dark-theme: map.get($color-config, 'is-dark');

  $foreground: map.get($color-config, foreground);
  $label-color: mat.m2-get-color-from-palette($foreground, secondary-text);

  $primary-palette: map.get($color-config, 'primary');
  $background: map.get($theme, 'background');

  .NgxEditor__MenuBar {
    background-color: scale-color(map.get($background, 'card'), $lightness: +30%);
  }

  .NgxEditor {
    color: if($is-dark-theme, white, black);
    background-color: map.get($background, 'card');
    border-bottom: 1px solid mat.m2-get-color-from-palette($primary-palette, 50) !important;

    &:focus-within:not([readonly]) {
      box-shadow: 0 1px 0 0 mat.m2-get-color-from-palette($primary-palette, 500) !important;
      border-bottom: 1px solid mat.m2-get-color-from-palette($primary-palette, 500) !important;
    }
    .NgxEditor__Placeholder:before {
      color: $label-color;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}

.NgxEditor {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-clip: padding-box !important;
  border-radius: 0 !important;
  border: none !important;
  position: relative !important;

  b {
    font-weight: 600;
  }
}
