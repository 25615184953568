@use 'sass:map';
@use '@angular/material' as mat;
@import "../utilities";

.tag {
  min-width: $icon-size;
  height: $icon-size;
  color: #fff;
  background: gray;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: fit-content;
  padding: 5px 10px;
  border-radius: $icon-size;
}
.tag ~ .tag {
  margin-left: 10px;
  margin-bottom: 10px;
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $background: map.get($theme, 'background');
  $is-dark-theme: map.get($color-config, 'is-dark');
  $foreground: map.get($color-config, foreground);
  $text-color: mat.m2-get-color-from-palette($foreground, text);
  $icon-color: mat.m2-get-color-from-palette($foreground, icon);
  $disabled-button: mat.m2-get-color-from-palette($background, disabled-button);

  $background-color: scale-color(map.get($background, 'card'), $lightness: +15%);
  @if $is-dark-theme {
    $background-color: scale-color(map.get($background, 'card'), $lightness: -15%);
  }

  .tag {
    background-color: $background-color;
    color: $text-color;
    border-color: $disabled-button;
    border-width: .5px;
    border-style: solid;
    mat-icon {
      color: $icon-color;
    }
  }
}
@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  $header-text: map.get($typography-config, 'subtitle-2');
  $normal-text: map.get($typography-config, 'body-2');
  .tag * {
    @each $prop, $value in $normal-text {
      $prop: $value;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
    @include typography($theme);
  }
}
