@use 'sass:map';
@use '@angular/material' as mat;

.mtx-grid {
  max-height: 78.5vh;
  border: 0 !important;
  outline: 1px solid rgba(255,255,255,0.2);

  .mat-mdc-standard-chip {
    height: unset;
  }

  .mat-mdc-cell.selected {
    box-shadow: none !important;
  }

  .mtx-grid-toolbar {
    min-height: 82px;
  }

  &.mtx-grid-toolbar-display-block, .mtx-grid-toolbar-display-block {
    .mtx-grid-toolbar {
      display: block !important;
    }
  }
}

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $is-dark-theme: map.get($color-config, 'is-dark');
  $background-powder-color: #b3d3ea;
  $background-yellow-color: #e6d178;
  $background-red-light-color: #e67878;
  $background-red-dark-color: #e64c4c;
  @if $is-dark-theme {
    $background-powder-color: #4c6071;
    $background-yellow-color: #7e6f3a;
    $background-red-light-color: #e67878;
    $background-red-dark-color: #e64c4c;
  }
  mtx-grid {
    .background-yellow {
      background-color: $background-yellow-color !important;
    }
    .background-red-light {
      background-color: $background-red-light-color !important;
    }
    .background-red-dark {
      background-color: $background-red-dark-color !important;
    }

    .background-powder {
      background-color: $background-powder-color !important;
    }
  }
}
@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  $header-text: map.get($typography-config, 'subtitle-2');
  $normal-text: map.get($typography-config, 'body-2');
  mtx-grid thead * {
    @each $prop, $value in $header-text {
      $prop: $value;
    }
  }
  mtx-grid tbody * {
    @each $prop, $value in $normal-text {
      $prop: $value;
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
    @include typography($theme);
  }
}
