$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
      0: 0,
      1: (
        $spacer * 0.25,
      ),
      2: (
        $spacer * 0.5,
      ),
      3: $spacer,
      4: (
        $spacer * 1.5,
      ),
      5: (
        $spacer * 3,
      ),
    ),
    $spacers
);
$cursors: (pointer, text, move, copy, 'no-drop', default);
$positions: static, relative, absolute, fixed, sticky !default;
$displays: none, inline, inline-block, block, table, table-row, table-cell, grid, flex, inline-flex !default;
$icon-size: 18px;
