@use 'sass:map';
@use '@angular/material' as mat;
@import "lib/gallery";
@import "lib/lightbox";

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $background: map.get($theme, 'background');

  gallery {
    background: map.get($background, 'card') !important;
  }

  .g-backdrop {
    //background-color: map.get($background, 'card') !important;
  }

}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}

gallery-image .g-image-item {
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

