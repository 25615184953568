/* You can add global styles to this file, and also import other style files 2 */
@import "components/index";
@import "utilities";
@import "libs/index";

$eform-font-family: Roboto, "Helvetica Neue", sans-serif;
// h-100 for base elements
html, body {
  height: 100vh;
  margin: 0;
  --theme-body-font-color: var(--black-800);
  font-family: $eform-font-family;

  .mat-mdc-option {
    font-size: unset !important;
  }
}

b, strong {
  font-weight: 500;
}
h2 {
  font-weight: 300;
}
.spacer {
  flex: 1 1 auto;
}

* { // for all elements with scroll
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: black;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #999999;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
}

body, .theme-light {
  --black-800: #242729;
  --blue-600: #0077cc;
  --theme-body-font-color: var(--black-800);
}

a {
  text-decoration: none;
}

//.mat-icon {
//  width: $icon-size !important;
//  height: $icon-size !important;
//  font-size: $icon-size;
//  line-height: $icon-size !important;
//}
//.mat-mdc-menu-item .mat-icon {
//  width: 18px;
//  height: 18px;
//  font-size: 18px;
//  line-height: 18px
//}

.h-100 {
  height: 100% !important;
}

.text-center {
  text-align: center!important;
}

.reportCaseUnarchive {
  color: green !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
}

.toast-bottom-right {
  bottom: 0;
  right: 7px;
}

.pt-6 {
  padding-top: 1.5em;
}
