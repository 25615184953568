@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $is-dark-theme: map.get($color-config, 'is-dark');
  $background: map.get(map.get($theme, 'background'), 'card');

  @if ($is-dark-theme) {
    /*Backgrounds*/
    $color-bg-darkest: #13141b;
    $color-bg-darker: #1b1e27;
    $color-bg-dark: #232837;
    $color-bg-med: #2f3646;
    $color-bg-light: #455066;
    $color-bg-lighter: #5b6882;

    /*Text*/
    $color-text-dark: #72809b;
    $color-text-med-dark: #919db5;
    $color-text-med: #A0AABE;
    $color-text-med-light: #d9dce1;
    $color-text-light: #f0f1f6;
    $color-text-lighter: #fff;

    background: $color-bg-darker;

    .ngx-charts {
      text {
        fill: $color-text-med;
      }

      .tooltip-anchor {
        fill: rgb(255, 255, 255);
      }

      .gridline-path {
        stroke: $color-bg-med;
      }

      .refline-path {
        stroke: $color-bg-light;
      }

      .reference-area {
        fill: #fff;
      }

      .grid-panel {
        &.odd {
          rect {
            fill: rgba(255, 255, 255, 0.05);
          }
        }
      }

      .force-directed-graph {
        .edge {
          stroke: $color-bg-light;
        }
      }

      .number-card {
        p {
          color: $color-text-light;
        }
      }

      .gauge {
        .background-arc {
          path {
            fill: $color-bg-med;
          }
        }

        .gauge-tick {
          path {
            stroke: $color-text-med;
          }

          text {
            fill: $color-text-med;
          }
        }
      }

      .linear-gauge {
        .background-bar {
          path {
            fill: $color-bg-med;
          }
        }

        .units {
          fill: $color-text-dark;
        }
      }

      .timeline {
        .brush-background {
          fill: rgba(255, 255, 255, 0.05);
        }

        .brush {
          .selection {
            fill: rgba(255, 255, 255, 0.1);
            stroke: #aaa;
          }
        }
      }

      .polar-chart .polar-chart-background {
        fill: rgb(30, 34, 46);
      }
    }

    .chart-legend {
      .legend-labels {
        background: $background !important;
      }

      .legend-item {
        &:hover {
          color: #fff;
        }
      }

      .legend-label {
        &:hover {
          color: #fff !important;
        }

        .active {
          .legend-label-text {
            color: #fff !important;
            text-overflow: clip !important;
            padding-right: 5px;
          }
        }
      }

      .scale-legend-label {
        color: $color-text-med;
      }
    }

    .advanced-pie-legend {
      color: $color-text-med;

      .legend-item {
        &:hover {
          color: #fff !important;
        }
      }

      .legend-items-container {
        .legend-items {
          overflow: visible !important;
        }
      }
    }

    .number-card .number-card-label {
      font-size: 0.8em;
      color: $color-text-med;
    }
  } @else { // light theme
    .advanced-pie-legend {
      .legend-items-container {
        .legend-items {
          overflow: visible !important;
        }
      }
    }
}
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}

.legend-title-text {
  text-overflow: clip !important;
  padding-right: 5px;
}

.advanced-pie-legend-wrapper {
  position: absolute !important;
}
