$foreground: #FFF;

.spinner-container{
  position: fixed;
  z-index: 999998;
  //height: 2em;
  //width: 2em;
  overflow: visible;
  width: 15em;
  //margin: auto;
  top: 42%;
  left: 44%;
  //bottom: 40%;
  //right: 0;
  //margin-top: -125px;
  //margin-top: 10em;
  //margin-left: 10em;
  //margin-left: -125px;
  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }
}



.load1 {
  .loader,
  .loader:before,
  .loader:after {
    background:$foreground;
    -webkit-animation:load1 1s infinite ease-in-out;
    animation:load1 1s infinite ease-in-out;
    width:1em;
    height:1.8em;
  }

  .loader {
    color:$foreground;
    line-height: 13em;
    text-indent:-1.5em;
    margin:88px auto;
    position:relative;
    font-size:14px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay:-0.16s;
    animation-delay:-0.16s;

    &:before,
    &:after {
      position:absolute;
      top:0;
      content:'';
    }

    &:before {
      left:-1.5em;
      -webkit-animation-delay:-0.32s;
      animation-delay:-0.32s;
    }

    &:after {
      left:1.5em;
    }

  }

}

@mixin load1-frames {
  0%,
  80%,
  100% {
    box-shadow:0 0;
    height:3em;
  }

  40% {
    box-shadow:0 -2em;
    height:4em;
  }
}

@-webkit-keyframes load1 {@include load1-frames;}
@keyframes load1 {@include load1-frames;}
