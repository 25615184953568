@import "variables";
@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
    .#{$abbrev}t-#{$size}, // .mt-$size || .pt-$size
    .#{$abbrev}y-#{$size} { // .my-$size || .py-$size
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.need-wrapper {
  .mat-mdc-form-field ~ .mat-mdc-form-field {
    padding-left: 1rem;
  }
  &.flex-column mat-mdc-form-field ~ formatting-text-editor {
    padding-top: 1rem;
  }
  &.flex-row mat-mdc-form-field ~ formatting-text-editor {
    padding-left: 1rem;
  }

  .mat-mdc-card ~ .mat-mdc-card {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media (orientation: portrait) {
  .need-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .need-wrapper .mat-mdc-form-field ~ .mat-mdc-form-field {
    padding-left: 0 !important;
  }
}
