gallery[gallerize] {
  gallery-item {
    cursor: pointer;
  }
}

gallery-item, gallery-thumb {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
  overflow: hidden;
}

gallery-item {
  z-index: 10;
  video {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

gallery-thumb {
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 300ms cubic-bezier(0.5, 0, 0.5, 1);
  &.g-active-thumb {
    opacity: 1;
  }
}

.g-image-item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.g-template {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.g-loading {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  top: 50%;
  width: 80px;
  height: 80px;
}

gallery[imageSize='contain'] gallery-slider .g-image-item {
  background-size: contain;
}
