/**
 * Mixins
 * -- You should DEFINITELY be using Bourbon or Autoprefixer instead!
 */

=transform($trans)
  -webkit-transform: $trans
  -moz-transform: $trans
  -ms-transform: $trans
  -o-transform: $trans
  transform: $trans

=transition($trans)
  -webkit-transition: $trans
  -moz-transition: $trans
  -ms-transition: $trans
  -o-transition: $trans
  transition: $trans

=size($val)
  width: $val
  height: $val
