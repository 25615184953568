.g-counter {
  z-index: 50;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) perspective(1px);
  bottom: 0;
  font-size: 10px;
  padding: 4px 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
