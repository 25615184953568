@import '../../utilities/colors';

.pell {
  border-radius: 5px;
  box-shadow: 0 2px 3px hsla(0, 0%, 4%, 0.1), 0 0 0 1px hsla(0, 0%, 4%, 0.1);
}
.pell,
.pell-content {
  box-sizing: border-box;
  width: 100%;
}
.pell-content {
  height: 150px;
  outline: 0;
  overflow-y: auto;
  padding: 10px;
  border-bottom: 1px solid hsla(0, 0%, 4%, 0.2);
}
.pell-actionbar {
  background-color: #fff;
  border-bottom: 1px solid hsla(0, 0%, 4%, 0.2);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  & button:focus {
    outline: none;
  }
  & button:active {
    background-color: rgb(100, 100, 100, .1);
  }
}
.pell-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 30px;
  outline: 0;
  width: 30px;
}

body.theme-dark {
  .pell-actionbar {
    background-color: var(--black-025);
    .pell-button {
      color: var(--theme-body-font-color);
    }
  }
  .pell-content {
    border-bottom: 1px solid var(--black-300);
  }
}
