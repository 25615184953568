@import "../../utilities/colors"

/**
 * Radio Input Variables
 */

$radio-inner-size     	: 13px
$radio-outer-size     	: 23px

$radio-font-size       	: 16px

$radio-text-color      	: #5B5B5B
$radio-inactive-color 	: $eform-gray
$radio-active-color   	: $eform-red
$radio-inner-color   		: #fff
$radio-error-color   		: #d32f2f // red-700

$radio-transition     	: all 0.25s ease-in-out
